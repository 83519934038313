<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar :inputs="inputs" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="plus" @click="showAdd('add')" style="margin-right: 10px">创建问卷</a-button>
        </div>
      </div>

      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <template slot="status" slot-scope="record">
            {{ record.status == 0 ? "未投放" : "已投放" }}
          </template>
          <template slot="begin_date" slot-scope="record">
            {{record.begin_date}}
          </template>
          <template slot="end_date" slot-scope="record">
            {{record.end_date}}
          </template>
          <template slot="action" slot-scope="record">
            <a-tooltip title="复制">
              <a-button type="link" @click="handleCopy(record)" >复制</a-button>
            </a-tooltip>
            <a-divider type="vertical" v-if="record.status == 1" />
            <a-tooltip title="查看结果" v-if="record.status == 1">
              <a-button type="link" @click="showResult(record)" >搜索</a-button>
            </a-tooltip>
            <a-divider type="vertical" v-if="record.status == 0" />
            <a-tooltip title="编辑" v-if="record.status == 0">
              <a-button type="link" @click="showAdd(record)" >编辑</a-button>
            </a-tooltip>
            <a-divider type="vertical" v-if="record.status == 0" />
            <a-tooltip title="发布" v-if="record.status == 0">
              <a-button type="link" @click="handlePut(record)" >发布</a-button>
            </a-tooltip>
            <a-divider type="vertical" v-if="record.status == 1" />
            <a-tooltip title="导出" v-if="record.status == 1">
              <a-button type="link" @click="handleExport(record)" >导出</a-button>
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip title="删除">
              <a-button type="link" @click="deleteFn(record)" >删除</a-button>
            </a-tooltip>

          </template>
        </a-table>
      </div>
    </div>

    <!-- 展示结果 -->
    <a-modal :width="800" :height="500" v-model="showResultFlag" title="问卷结果" @ok="handleSureResult" okText="确定">
      <!-- 循环.result_record -->
      <div v-for="(item,index) in totalList" :key="item.id" class="result_record">
        <h3>{{index + 1}}.{{item.title}}</h3>
        <!-- 循环.dataLine -->
        <div v-for="subItem in item.option" :key="subItem.id" class="dataLine">
          <div class="left" :style="`width:${subItem.percent == 'NaN%' ? '0px' : subItem.percent}`">
          </div>
          <div class="text">
            <span class="title">{{subItem.title}}</span>
            <span class="percent"
              :style="`color:${(subItem.percentPoint == NaN ? 0 : subItem.percentPoint) >= 0.99 ? 'white' : 'black'}`">{{subItem.percent == 'NaN%' ? '0%' : subItem.percent}}</span>
            <span class="number"
              :style="`color:${(subItem.percentPoint == NaN ? 0 : subItem.percentPoint) >= 0.8 ? 'white' : 'black'}`">{{subItem.count}}</span>
          </div>
        </div>
        <a-button class="pie_btn" :type="`${item.showPie == true ? '' : 'primary'}`" @click="showPieFn(item)">
          饼状图
        </a-button>
        <ve-pie :tooltip-visible="false" :ref="item.id" v-if="item.showPie" :data="item.chartData"
          :legend-visible="true" height="400px">
        </ve-pie>
      </div>
    </a-modal>

    <!-- 添加问卷 -->
    <a-modal :maskClosable="false" :keyboard="false" :width="800" v-model="visible" :title="addFlag ? '添加问卷' : '编辑问卷'"
      :closable="false" okText="保存" @ok="handleSure" @cancel="handleCancel">
      <a-form-model :model="addParam" :rules="rules" ref="addForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-model-item label="问卷标题" prop="title">
          <a-input v-model="addParam.title" placeholder="第一季度食堂满意度调查" />
        </a-form-model-item>
        <a-form-model-item label="问卷描述" prop="description">
          <a-input v-model="addParam.description"
            placeholder="为了给您提供更好的服务，希望您能抽出几分钟时间，将您的感受和建议告诉我们，我们非常重视每位用户的宝贵意见，期待您的参与！现在我们就马上开始吧！" type="textarea" />
        </a-form-model-item>
        <a-form-model-item label="问题">
          <a-button v-if="qsList.length == 0" block @click="showAddQuestion" >添加</a-button>
          <div v-else>
            <div class="q_box">
              <div class="q_item_style" v-for="(item,index) in qsList" :key="item.id" @mouseenter="mouseEnter(item.id)"
                @mouseleave="mouseLeave">
                <div class="q_left">
                  <div style="padding-left:10px;font-weight:bold;">{{index + 1}}.{{item.title}}</div>
                  <div style="padding-left:20px;" v-for="subItem in item.option" :key="subItem.id">
                    <a-icon type="border" />{{subItem.title}}
                    <div>
                      <img style="width:200px;" v-if="subItem.img" :src="subItem.img" alt srcset>
                    </div>
                  </div>
                </div>
                <!-- btn_show 必须是id 不能是index 因为index会有0的情况 -->
                <div class="q_right" v-if="btn_show == item.id ? true : false">
                  <a-tooltip title="编辑" @click="editQs(item.id)">
                    <a-button type="link" >编辑</a-button>
                  </a-tooltip>
                  <a-tooltip title="删除" @click="deleteQs(item.id)">
                    <a-button type="link" >删除</a-button>
                  </a-tooltip>
                  <a-tooltip title="下移" @click="MoveDownQs(index)"
                    v-if="index != qsList.length - 1 && qsList.length > 1">
                    <a-button type="link" >上移</a-button>
                  </a-tooltip>
                  <a-tooltip title="上移" @click="MoveUpQs(index)" v-if="index != 0 && qsList.length > 1">
                    <a-button type="link" >下移</a-button>
                  </a-tooltip>
                </div>
              </div>
            </div>
            <a-button type="link" @click="showAddQuestion">添加问题</a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item label="结束语" prop="conclusion">
          <a-input v-model="addParam.conclusion" />
        </a-form-model-item>
        <a-form-model-item label="开始时间" prop="begin_date">
          <a-date-picker v-model="addParam.begin_date" format="YYYY-MM-DD" placeholder="开始时间"
            @change="beginTimeChange" />
        </a-form-model-item>
        <a-form-model-item label="结束时间" prop="end_date">
          <a-date-picker v-model="addParam.end_date" format="YYYY-MM-DD" placeholder="结束时间" @change="endTimeChange" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 添加问题 -->
    <a-modal :maskClosable="false" :keyboard="false" :closable="true" :width="800" v-model="visibleAddQ"
      :title="addQsFlag ? '添加问题' : '编辑问题'">
      <a-form-model :rules="rulesAddQs" :model="addQsParam.topic" ref="addQsForm" :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }">
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="addQsParam.topic.title" placeholder="请输入问题标题" />
        </a-form-model-item>
        <a-form-model-item label="选项" :rules="{
                                  required: true,
                                  message: '请输入选项描述',
                                  trigger: 'blur',
                                }">
          <div v-for="(item,index) in addQsParam.topic.options" :key="index">
            <a-input class="w_85p" v-model="item.title" placeholder="请输入选项描述" />

            <a-upload name="link_url" :multiple="false" :action="uploadUrl" :headers="headers"
              @change="(res) => handleUploadChange(res,index)" :showUploadList="false" :beforeUpload="beforeUpload">
              <a-tooltip title="上传图片">
                <a-button type="link" >图片</a-button>
              </a-tooltip>
            </a-upload>
            <a-tooltip title="删除选项">
              <a-button type="link" @click="deleteQsOpt(index)" >删除</a-button>
            </a-tooltip>
            <img style="width:200px;" v-if="item.img" :src="item.img" alt srcset />
          </div>
          <a-button type="link" @click="addOption">添加选项</a-button>
        </a-form-model-item>
        <a-form-model-item label="设置(最多可选)">
          <a-select v-model="addQsParam.topic.required_num" class="w_20p">
            <a-select-option v-for="select in maxSelects" :key="select.key" :value="select.key">{{select.value}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>

      <template slot="footer">
        <a-button @click="saveQs('back')">
          保存并返回
        </a-button>
        <a-button v-if="addQsFlag" type="primary" :loading="loading" @click="saveQs('next')">
          保存并创建下一条
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  addQuestionnaire,
  updateQuestionnaire,
  totalQuestionnaire,
  addQuestion,
  updateQuestion,
  getQuestion,
  deleteQuestion,
  getQuestionnaires,
  getQuestions,
  pushQuestionnaire,
  deleteQuestionnaire,
  copyQuestionnaire,
  exportQuestionnaire
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
import moment from "moment";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "拓展应用",
        },
        {
          key: 1,
          title: "问卷调查",
        },
      ],
      //传给搜索组件的参数
      inputs: [
        {
          placeholder: "请输入问卷标题",
          model: "title",
          width: 200,
        },
      ],

      //请求参数
      queryParam: {
        page: 1,
        title: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "问卷标题",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "状态",
          key: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "开始时间",
          key: "begin_date",
          scopedSlots: { customRender: "begin_date" }
        },
        {
          title: "结束时间",
          key: "end_date",
          scopedSlots: { customRender: "end_date" }
        },
        {
          title: "参与人数",
          key: "user_count",
          dataIndex: "user_count",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },

      // 添加问卷弹窗
      visible: false,
      addFlag: false,
      QuestionnaireId: '',//问卷id

      //更新参数
      addParam: {
        title: "第一季度食堂满意度调查",
        begin_date: moment(this.getCurrentData(), "YYYY-MM-DD"),
        end_date: moment(this.getCurrentData(), "YYYY-MM-DD"),
        description: '为了给您提供更好的服务，希望您能抽出几分钟时间，将您的感受和建议告诉我们，我们非常重视每位用户的宝贵意见，期待您的参与！现在我们就马上开始吧！',
        conclusion: '感谢您的配合，可前往小卖部领取小礼品一份！！！'
      },
      // 
      endOpen: false,

      //添加问卷校验规则
      rules: {
        title: [{ required: true, message: "请输入问卷标题", trigger: "blur" }],
        description: [{ required: true, message: "请输入问卷描述", trigger: "blur" }],
        conclusion: [{ required: true, message: "请输入问卷结束语", trigger: "blur" }],
        begin_date: [{ required: true, message: "请选择开始时间", trigger: "blur" }],
        end_date: [{ required: true, message: "请选择结束时间", trigger: "blur" }],
      },
      // 添加问题弹窗
      visibleAddQ: false,
      rulesAddQs: {
        title: [{ required: true, message: "请输入问题标题", trigger: "blur" }],
      },
      qsId: '',
      addQsParam: {
        questionnaire_id: '',
        topic:
        {
          title: '',
          required_num: 1,
          // 问题选项最少两个
          options: [
            {
              title: '',
              img: ''
            },
            {
              title: '',
              img: ''
            }
          ]
        },

      },
      uploadUrl:
        (process.env.NODE_ENV == "development"
          ? "/api"
          : "https://stgl.51baocan.cn") + "/home/questionnaire/topic/upload",
      headers: {
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
      maxSelects: [
        {
          key: 0,
          value: '不限'
        },
        {
          key: 1,
          value: '1个'
        },
        {
          key: 2,
          value: '2个'
        },
        {
          key: 3,
          value: '3个'
        },
        {
          key: 4,
          value: '4个'
        },
        {
          key: 5,
          value: '5个'
        },
        {
          key: 6,
          value: '6个'
        },
        {
          key: 7,
          value: '7个'
        },
        {
          key: 8,
          value: '8个'
        },
        {
          key: 9,
          value: '9个'
        },
        {
          key: 10,
          value: '10个'
        },
      ],
      qsList: [],
      btn_show: null,
      addQsFlag: false,
      // 结果弹窗
      showResultFlag: false,
      totalList: [],
      // chartData: {
      //   columns: ['类型', '用户'],
      //   rows: [
      //     { '类型': '不满意', '用户': 20 },
      //     { '类型': '满意', '用户': 80 }
      //   ]
      // },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    moment,
    getCurrentData () {
      return new Date().toLocaleDateString();
    },
    getTomorrow () {
      let day = new Date();
      day.setTime(day.getTime() + 24 * 60 * 60 * 1000);
      return day.toLocaleDateString();
    },
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        page: 1,
        title: val.input.title ? val.input.title : "",
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      getQuestionnaires(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;
        //空列表判断
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        //列表数据处理
        this.tableData = resultData.data;
        this.loading = false;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    // changeShouPie () {
    //   this.showPie = !this.showPie
    // },

    //显示添加模态框
    async showAdd (record) {
      if (record == "add") {
        // 添加问卷
        this.qsList = [];
        this.addFlag = true;
        this.addParam = {
          title: "第一季度食堂满意度调查",
          description: '为了给您提供更好的服务，希望您能抽出几分钟时间，将您的感受和建议告诉我们，我们非常重视每位用户的宝贵意见，期待您的参与！现在我们就马上开始吧！',
          conclusion: '感谢您的配合，可前往小卖部领取小礼品一份！！！',
          begin_date: moment(this.getCurrentData(), "YYYY-MM-DD"),
          end_date: moment(this.getTomorrow(), "YYYY-MM-DD"),
        };
        let param = {
          ...this.addParam,
          begin_date: moment(new Date()).format('YYYY-MM-DD'),//今天
          end_date: moment().add(1, "day").format("YYYY-MM-DD")//明天
        }
        await addQuestionnaire(param).then(res => {}).catch(err => {
          if (err.status == 201) {
            this.visible = true
            this.$message.success('创建问卷模板成功')
            this.QuestionnaireId = err.data.id
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        })
      } else {
        // 修改问卷
        this.QuestionnaireId = record.id
        this.getQuestionsFn(this.QuestionnaireId)
        this.addParam = {
          title: record.title,
          description: record.description,
          conclusion: record.conclusion,
          begin_date: moment(record.begin_date),
          end_date: moment(record.end_date)
        };
        this.addFlag = false;
        this.visible = true
      }
    },
    beginTimeChange (time, timeStr) {
      this.addParam.begin_date = timeStr
    },
    endTimeChange (time, timeStr) {
      this.addParam.end_date = timeStr
    },

    showAddQuestion () {
      this.addQsParam = {
        questionnaire_id: this.QuestionnaireId,
        topic:
        {
          title: '',
          required_num: 1,
          // 问题选项最少两个
          options: [
            {
              title: '',
              img: ''
            },
            {
              title: '',
              img: ''
            }
          ]
        },
      }
      this.visibleAddQ = true;
      this.addQsFlag = true;
    },

    handleUploadChange (res, index) {
      let file = res.file;
      if ((file.status == "done" || file.status == "error") && file.size > (1024 * 300)) {
        this.$message.info('请上传300kb以下的图片')
        return
      }
      // if(file.status == "done" && (file.width/file.height != 16/9)){
      //   this.$message.info('图片宽高比需为16比9')
      //   return
      // }
      if (!!res.file.response) {
        this.addQsParam.topic.options[index].img = res.file.response.path;
      }
    },

    // 上传文件之前的钩子
    beforeUpload (file) {
      let self = this;
      return new Promise(function (resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = e => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function () {
            // 获取图片的宽高，并存放到file对象中
            file.width = this.width;
            file.height = this.height;
            resolve();
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },

    deleteQsOpt (index) {
      if (this.addQsParam.topic.options.length <= 2) {
        this.$message.error('选项最少2个')
        return
      }
      this.$confirm({
        title: '确定删除该选项？',
        onOk: () => {
          this.addQsParam.topic.options.splice(index, 1)
        }
      })
    },

    //添加选项
    addOption () {
      let opt = {
        title: '',
        img: ''
      }
      this.addQsParam.topic.options.push(opt)
    },

    // 保存并返回/创建下一条
    saveQs: util.DebounceBy(function (type) {
      this.$refs["addQsForm"].validate((valid) => {
        if (valid) {
          for (let i = 0; i < this.addQsParam.topic.options.length; i++) {
            if (this.addQsParam.topic.options[i].title == '') {
              this.$message.error(`第${i + 1}个选项描述不能为空`)
              return
            }
          }
          // 最多可选项要小于等于选项数量
          if (this.addQsParam.topic.required_num > this.addQsParam.topic.options.length) {
            this.$message.error('设置(最多可选项)要小于等于选项数量')
            return
          }
          if (this.addQsFlag) {
            let param = this.addQsParam;
            if (param.topic.required_num == 0) {
              //传-1：不限
              param.topic.required_num = -1
            }
            // 添加问题
            addQuestion(param).then().catch(err => {
              if (err.status == 201) {
                this.$message.success('保存成功')
                this.getQuestionsFn(this.QuestionnaireId)
                if (type == 'next') {
                  // 保存并创建下一条
                  this.addQsParam = {
                    questionnaire_id: this.QuestionnaireId,
                    topic:
                    {
                      title: '',
                      required_num: 1,
                      // 问题选项最少两个
                      options: [
                        {
                          title: '',
                          img: ''
                        },
                        {
                          title: '',
                          img: ''
                        }
                      ]
                    },
                  }
                } else {
                  // 保存并返回
                  this.visibleAddQ = false
                }
              } else {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              }
            })
          } else {
            // 编辑问题
            let param = {
              questionnaire_id: this.addQsParam.questionnaire_id,
              title: this.addQsParam.topic.title,
              required_num: this.addQsParam.topic.required_num == 0 ? -1 : this.addQsParam.topic.required_num,
              options: this.addQsParam.topic.options.map(item => {
                delete item.created_at;
                delete item.deleted_at;
                delete item.topic_id;
                delete item.updated_at
                return item
              })
            }
            updateQuestion(this.qsId, param).then(res => {
              this.$message.success('保存成功')
              this.getQuestionsFn(this.QuestionnaireId)
              this.visibleAddQ = false
            }).catch(err => {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            })
          }
        }
      }
      );
    }, 3000),

    // 获取问题列表
    getQuestionsFn (id) {
      getQuestions(id).then(res => {
        this.qsList = res.data
      }).catch(err => {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      })
    },

    // 查看结果
    showResult (r) {
      if (r.status == 0) {
        this.$message.warning('该问卷暂未投放')
        return
      }
      totalQuestionnaire(r.id).then(res => {
        let list = res.data.topic.map(item => {
          item.chartData = {
            columns: ['选项', '得票数'],
            rows: []
          }
          item.showPie = false
          item.option.map(subItem => {
            subItem.count = subItem.count == null ? 0 : subItem.count.count;
            subItem.percent = (subItem.count / item.topic_user_count).toFixed(2) * 100 + '%';
            subItem.percentPoint = subItem.count / item.topic_user_count
            item.chartData.rows.push({
              '选项': subItem.title + ':' + subItem.percent,
              '得票数': subItem.count
            })
            return subItem
          })
          return item
        })
        this.totalList = list
        this.showResultFlag = true
      }).catch(err => {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      })

    },

    showPieFn (item) {
      item.showPie = !item.showPie
      this.$forceUpdate()
    },

    //发布
    handlePut (record) {
      this.$confirm({
        title: `是否发布问卷:${record.title}`,
        onOk: () => {
          pushQuestionnaire(record.id)
            .then((res) => { })
            .catch((err) => {
              if (err.status == 201) {
                this.$message.success("发布成功");
                this.getTableData();
              } else {
                this.$message.error(err.data.msg)
              }
            });
        },
      });
    },

    //导出
    handleExport (r) {
      exportQuestionnaire(r.id).then((res) => {
        util.acceptFileStream(res.data, "问卷投票详情.xls");
      });
    },

    // 查询问卷详情
    getQuestionnaireDetailFn () {
      this.getQuestionsFn(this.QuestionnaireId)

      this.addFlag = false;
      this.visible = true
    },

    //复制
    async handleCopy (record) {
      let copyId = record.id, copyTitle = record.title;
      this.$confirm({
        title: `确定拷贝问卷${copyTitle}?`,
        onOk: () => {
          copyQuestionnaire(copyId).then().catch(err => {
            if (err.status = 201) {
              this.$message.success('复制成功,列表第一项为新复制的问卷')
              this.getTableData();
            }
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          })
        }
      })

    },

    //确定保存问卷 p.update = true就是调整问题排序 否则是编辑问卷
    handleSure: util.DebounceBy(function (p) {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          let param = {
            ...this.addParam,
            begin_date: moment(this.addParam.begin_date).format("YYYY-MM-DD"),
            end_date: moment(this.addParam.end_date).format("YYYY-MM-DD"),
          }
          if (p.update) {
            param.data = p.list
          } else {
            delete param.data
          }
          updateQuestionnaire(this.QuestionnaireId, param).then().catch(err => {
            if (err.status == 201) {
              if (p.update) {
                this.$message.success('操作成功')
                this.getQuestionsFn(this.QuestionnaireId)
              } else {
                this.$message.success('保存成功')
                this.visible = false;
                this.getTableData();
              }
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          })
        }
      })
    }, 3000),

    handleCancel () {
      this.getTableData()
    },

    //删除问卷
    deleteFn (record) {
      this.$confirm({
        title: `是否删除问卷:${record.title}`,
        onOk: () => {
          deleteQuestionnaire(record.id)
            .then((res) => { })
            .catch((err) => {
              if (err.status == 204) {
                this.$message.success("删除成功");
                this.getTableData();
              } else {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              }
            });
        },
      });
    },

    mouseEnter (id) {
      // btn_show 必须是id 不能是index 因为index会有0的情况
      this.btn_show = id
    },

    mouseLeave () {
      this.btn_show = null
    },

    editQs (id) {
      this.qsId = id;
      getQuestion(id).then(res => {
        let data = res.data
        this.addQsParam = {
          questionnaire_id: data.questionnaire_id,
          topic: {
            title: data.title,
            required_num: data.required_num == -1 ? 0 : data.required_num,
            options: data.option
          }
        }
        this.addQsFlag = false
        this.visibleAddQ = true
      }).catch(err => {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      })
    },

    deleteQs (id) {
      this.$confirm({
        title: '确定删除该问题？',
        onOk: () => {
          deleteQuestion(id).then().catch(err => {
            if (err.status == 204) {
              this.$message.success('删除成功')
              this.getQuestionsFn(this.QuestionnaireId)
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          })
        }
      })

    },

    //问题排序下移
    MoveDownQs (index) {
      let current = index, next = index + 1, currentSort = this.qsList[current].sort,
        nextSort = this.qsList[next].sort, list = this.qsList;
      // 交换sort
      list[current].sort = nextSort,
        list[next].sort = currentSort
      let newList = list.map(item => {
        return {
          sort: item.sort,
          id: item.id
        }
      })
      this.handleSure({ list: newList, update: true })
    },

    //问题排序上移
    MoveUpQs (index) {
      let current = index, pre = index - 1, currentSort = this.qsList[current].sort,
        preSort = this.qsList[pre].sort, list = this.qsList;
      // 交换sort
      list[current].sort = preSort,
        list[pre].sort = currentSort
      let newList = list.map(item => {
        return {
          sort: item.sort,
          id: item.id
        }
      })
      this.handleSure({ list: newList, update: true })
    },



    handleSureResult () {
      // console.log('确定问卷结果')
    },



  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
.w_85p {
  width: 85%;
}
.w_20p {
  width: 20%;
}
.q_box {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}
.q_item_style {
  min-height: 140px;
  width: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
}
.q_item_style:hover {
  box-shadow: 2px 2px 4px 1px grey;
  border-radius: 5px;
}
.q_left {
  width: 92%;
}
.q_right {
  min-height: 140px;
  // max-height: 120px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 5%;
  justify-content: center;
}
.result_record {
  .dataLine {
    width: 700px;
    height: 23px;
    border: 1px solid #ff833d;
    border-radius: 5px;
    margin: 5px auto;
    position: relative;
    .left {
      // border-radius: 3px;
      background-color: #ff833d;
      float: left;
      padding-left: 10px;
      width: 700px;
      height: 100%;
    }
    .text {
      width: 100%;
      left: 0;
      position: absolute;
      .title {
        margin-left: 10px;
        // color: white;
      }
      .percent {
        float: right;
        margin: 0 10px 0 100px;
      }
      .number {
        float: right;
      }
    }
  }
  .pie_btn {
    margin-left: 25px;
  }
}
</style>